import React from "react"
import { graphql, Link } from "gatsby"
import SEO from "../components/seo"
import * as $ from 'jquery'
import Img from "gatsby-image"
import "../styles/news.css"
import Helmet from "react-helmet"
import Slider from "react-slick";
import {monthNames, pad} from '../components/names'
import SignUp from "../components/form/SignUp"

export default ({ data }) => {

  const sliderOptions = {
    variableWidth: true,
    slidesToShow: 2,
    infinite: false,
    responsive: [{
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          variableWidth: false,
          arrows: true
        }
    },
    {
      breakpoint: 600,
      settings: {
        variableWidth: false,
        slidesToShow: 1,
        arrows: true
      }
    }]
  }

if (typeof document !== 'undefined') {
$( document ).ready(function() {
  $('.open-iframe').click(function(){
    var frameName = $(this).attr('frame');
    $(frameName).addClass("active");
    $('body').addClass("noscroll")
  })

  $('.open-map').click(function(){
    var frameName = $(this).attr('frame');
    $(frameName).addClass("active");
    $(".race--hotspots-frame").addClass("active");
    $(".race--circuit-map").addClass("blurred")
})

$('.race--hotspots-frame section .close').click(function(){
    $(".race--hotspots-frame section").removeClass("active");
    $(".race--hotspots-frame").removeClass("active");
    $(".race--circuit-map").removeClass("blurred")
})

$('.fullscreen--video-player').click(function(){
    $('.fullscreen--video-player').removeClass("active");
    $('body').removeClass("noscroll");
    var src= $('.fullscreen--video-player iframe').attr('src');
    $('.fullscreen--video-player iframe').attr('src',src);  
})

  $('img').unwrap("p");
  })
}
  return (
    <>
      <SEO title={data.directusNews.name} />
      <Helmet>
        <meta property="og:image" content={data.directusNews.placeholder_image.localFile.url} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://www.mbracing.dk/race/${pad(new Date(data.directusNews.posted_on.replace(/-/g, '/')).getFullYear())}/${pad(new Date(data.directusNews.posted_on.replace(/-/g, '/')).getMonth()+1)}/${data.directusNews.url}`} />
        <meta property="og:image:alt" content={`Cover image for ${data.directusNews.name}`} />
      </Helmet>

        <nav className="nav--top-back">
          <Link to="/blog">Tilbage til nyhedsoversigten</Link>
        </nav>
        <section className="news--entry-date">
          <p>{`${pad(new Date(data.directusNews.posted_on.replace(/-/g, '/')).getDate())}. ${pad(new Date(data.directusNews.posted_on.replace(/-/g, '/')).getMonth() + 1)}. ${new Date(data.directusNews.posted_on.replace(/-/g, '/')).getFullYear()}`}</p>
        </section>
        <section className="news--entry-image">
          <Img fluid={data.directusNews.placeholder_image.localFile.childImageSharp.fluid}/>
        </section>
        <section className="news--entry-heading">
          <h3>{data.directusNews.name}</h3>
        </section>
        <section dangerouslySetInnerHTML={{__html:data.directusNews.text}} className="news--text opaque80"></section>
        {data.directusNews.signup_form && 
          <SignUp name={data.directusNews.name} closed={new Date() > new Date(data.directusNews.posted_on.replace(/-/g, '/'))} amount={data.directusNews.amount}/>
        }
        {data.directusNews.gallery !== null && (
          <section className="news--gallery">
            <Slider {...sliderOptions} className="news--gallery-slider">
              {data.directusNews.gallery.map((item, index) => {
                return(
                  <figure className="news--gallery-item" key={index}>
                    <Img fluid={item.image.localFile.childImageSharp.fluid}/>
                    {item.name !== null && (
                      <figcaption>{item.name}</figcaption>
                    )}
                  </figure>
                )
              })}
            </Slider>
          </section>
        )}

        <section className="news--other-news">
          <div className="news--other-nav">
            <h2>Andre nyheder</h2>
            <Link to="/blog">Tilbage til nyhedsoversigten</Link>
          </div>
          <section className="news--other-articles">
            {data.allDirectusNews.edges.map(edge => (
              <Link to={edge.node.url ? `/${pad(new Date(edge.node.posted_on.replace(/-/g, '/')).getFullYear())}/${pad(new Date(edge.node.posted_on.replace(/-/g, '/')).getMonth()+1)}/${edge.node.url}` : ""} className="news--other-new" key={edge.node.id}>
                <article>
                  <Img fluid={edge.node.placeholder_image.localFile.childImageSharp.fluid}/>
                  <p className="news--other-time">{monthNames[new Date(edge.node.posted_on.replace(/-/g, '/')).getMonth()] + " " + new Date(edge.node.posted_on.replace(/-/g, '/')).getDate() + ", " + new Date(edge.node.posted_on.replace(/-/g, '/')).getFullYear()}</p>
                  <p>{edge.node.name}</p>
                  <span className="news--other-link">Læs artiklen</span>
                </article>
              </Link>
            ))}
          </section>
        </section>
    </>
  )
}

export const query = graphql`
  query($id: String!, $posted: Date!) {
    directusNews(id: { eq: $id}) {
      id
      name
      posted_on
      text
      signup_form
      amount
      url
      placeholder_image {
        localFile {
          url
          childImageSharp {
            fluid(quality: 85) {
              ...GatsbyImageSharpFluid_withWebp                
            }
          }
        }
      }
      gallery {
        id
        name
        image {
          localFile {
            childImageSharp {
              fluid(quality: 75, maxWidth: 600) {
                ...GatsbyImageSharpFluid_withWebp                
              }
            }
          }
        }
      }
    }
    allDirectusNews(filter: {posted_on: {lt: $posted}, status: {eq: "published"}, type: {eq: "news"}}, limit: 2) {
      edges {
        node {
          id
          name
          posted_on
          url
          placeholder_image {
            localFile {
              childImageSharp {
                fluid(quality: 60, maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp                
                }
              }
            }    
          }
        }
      }
    }
  }

`
